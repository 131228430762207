<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewContainerTypeForm"
        ref="addNewContainerTypeForm"
        class="custom-form pt-6"
        @submit.prevent="submitNewContainerTypeForm"
      >
        <v-col cols="12">
          <v-text-field
            outlined
            label="Typ kontenera"
            v-model="containerType.name"
            :rules="[rules.required]"
            placeholder="Wpisz nazwę kontenera"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            v-model.number="containerType.volume"
            type="number"
            :rules="[rules.required, rules.number, rules.lowerThan(1001)]"
            placeholder="Wpisz rozmiar kontenera"
            validate-on-blur
            @wheel="$event.target.blur()"
          >
            <template #label>
              Rozmiar kontenera [m<sup>3</sup>]
            </template>
          </v-text-field>
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewContainerTypeForm"
      >
        Dodaj typ kontenera
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      containerType: {
        name: null,
        volume: null,
      },
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.containerTypes.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewContainerType: 'containerTypes/addNewItem',
      getContainers: 'containerTypes/getItems',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    submitNewContainerTypeForm () {
      if (this.$refs.addNewContainerTypeForm.validate()) {
        const params = {
          ...this.containerType,
          volume: this.containerType.volume * 1000
        }
        this.addNewContainerType({ params })
          .then(() => {
            this.getContainers()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
